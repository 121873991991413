<!--
 * @Author: zhuchaowei
 * @Date: 2024-05-24 11:50:44
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-05-29 10:09:44
 * @Description: 
-->
<template>
  <div class="kpi-dr">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      destroy-on-close
      width="1196px"
    >
      <div class="xq-box">
        <div class="xq-box-success">
          <i
            class="el-icon-success"
            style="color: rgba(40, 177, 139, 1); font-size: 20px"
          ></i
          >你已成功导入{{ importObj.systemDataCount }}条收款记录
        </div>
        <div class="xq-box-error">
          <i
            class="el-icon-warning"
            style="color: rgba(248, 91, 92, 1); font-size: 20px"
          ></i
          >收款记录导入失败：{{ importObj.failCount }}条
        </div>
        <div class="xq-box-table">
          <div class="xq-box-table-t">
            <div class="xq-box-table-t-title">
              <span>导入失败保单列表</span>
              <el-button icon="el-icon-download" type="text" @click="downloadExport">导出</el-button>
            </div>
            <el-table :data="importObj.failList || []" border height="480px">
              <el-table-column prop="index" label="ID" width="80">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="policyNo" label="保单号"> </el-table-column>
              <el-table-column prop="amount" width="80" label="保费">
              </el-table-column>
              <el-table-column prop="rate" label="手续费率">
                <template slot-scope="scope">
                  {{ scope.row.rate }}%
                </template>
              </el-table-column>
            </el-table>
            <div class="xq-box-table-t-num">总保费：{{ importObj.failAmount }}元</div>
          </div>
          <img src="@/assets/images/kpi/zhuangshi-mdy.png" class="xq-box-table-img" alt="" srcset="">
          <div class="xq-box-table-t">
            <div class="xq-box-table-t-title">
              <span>匹配系统保单</span>
            </div>
            <el-table :data="importObj.systemDataList || []" border height="480px" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="60"> </el-table-column>
              <el-table-column prop="index" label="ID" width="80">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="systemPolicyNo" label="保单号"> </el-table-column>
              <el-table-column prop="amount" width="80" label="保费">
              </el-table-column>
              <el-table-column prop="rate" label="手续费率" width="60">
                <template slot-scope="scope">
                  {{ scope.row.rate }}%
                </template>
              </el-table-column>
              <el-table-column prop="policyPremium" label="实收保费">
                <template slot-scope="scope">
                  <TextInput
                    :config="{
                      label: '',
                      type: 'input',
                      disabled: !collectionList.includes(scope.row.systemPolicyNo),
                      isNum: true,
                      width: '100px',
                      placeholder: '请输入实收保费',
                    }"
                    v-model="importObj.systemDataList[scope.$index].policyPremium"
                  ></TextInput>
                </template>
              </el-table-column>
            </el-table>
            <div class="xq-box-table-t-num">总保费：{{ importObj.systemDataAmount }}元</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button @click="submitForm" type="primary">完成</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { downloadKpiPreviewExport, importCollection } from "@/api/kpi.js";
import TextInput from "@/components/SimpleTable/TextInput.vue";
export default {
  name: "KpiImportDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "导入收款",
    },
    importObj: {
      type: Object,
      default: () => {}
    }
  },
  components: { TextInput },
  data() {
    return {
      dialogVisible: false,
      errTableData: [],
      collectionList: [],
    };
  },
  watch: {
    show(n) {
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
  },
  methods: {
    clearForm() {},
    handleClose(done) {
      this.clearForm();
      done();
    },
    uploadChange(file, fileList) {
      this.dataForm.file = file.raw;
    },
    async submitForm() {
      // this.$emit("submit", this.dataForm);
      if (this.collectionList && this.collectionList.length) {
        // .filter(v=> this.collectionList.includes(v.systemPolicyNo))
        let data = await importCollection(this.importObj.systemDataList);
        if (data.code == 200) {
          this.$emit('submit')
        };
      }
      this.clearForm();
      this.dialogVisible = false;
    },
    handleSelectionChange(e) {
      this.collectionList = e.map(val=> val.systemPolicyNo);
      this.importObj.systemDataList.map((e,ind) => {
        this.importObj.systemDataList[ind].hitFlag = false;
        if (this.collectionList.includes(e.systemPolicyNo)) {
          this.importObj.systemDataList[ind].policyPremiumOld = this.importObj.systemDataList[ind].policyPremium;
          this.importObj.systemDataList[ind].policyPremium = this.importObj.systemDataList[ind].amount;
          this.importObj.systemDataList[ind].hitFlag = true;
        }else if (this.importObj.systemDataList[ind].policyPremiumOld) {
          this.importObj.systemDataList[ind].policyPremium = this.importObj.systemDataList[ind].policyPremiumOld;
        }
      });
    },
    downloadExport() {
      downloadKpiPreviewExport(this.importObj.failList);
    }
  },
};
</script>

<style lang="scss" scoped>
.kpi-dr {
  ::v-deep .el-dialog {
    border-radius: 8px;
    .el-dialog__header {
      position: relative;
      padding: 0;
      padding-left: 24px;
      height: 40px;
      line-height: 40px;
      // background: #4278C9;
      border-radius: 8px 8px 0px 0px;
      .el-dialog__title {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }
      .el-dialog__headerbtn {
        top: 50%;
        transform: translateY(-50%);
        .el-dialog__close {
          font-weight: bold;
          font-size: 18px;
          color: #333333;
        }
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding: 16px;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
  }
  .xq-box {
    &-success {
      display: flex;
      align-items: center;
      height: 54px;
      font-weight: 400;
      font-size: 14px;
      color: #28b18b;
      margin-bottom: 16px;
      padding-left: 16px;
      background: rgba(40, 177, 139, 0.1);
      i {
        margin-right: 8px;
      }
    }
    &-error {
      display: flex;
      align-items: center;
      height: 54px;
      font-weight: 400;
      font-size: 14px;
      color: #fc3636;
      margin-bottom: 16px;
      padding-left: 16px;
      background: #fcf1f1;
      i {
        margin-right: 8px;
      }
    }
    &-table {
      display: flex;
      align-items: center;
      &-t {
        flex: 1;
        overflow: hidden;
        &-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 50px;
          padding: 0 16px;
          background: #f4f7fa;
          border: 1px solid #dee6ec;
        }
        &-num {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 50px;
          padding: 0 25px;
          font-weight: bold;
          font-size: 16px;
          color: #4278C9;
          border: 1px solid #DEE6EC;
        }
      }
      &-img {
        width: 40px;
        height: 84px;
        margin: 0 24px;
      }
      ::v-deep .el-table {
        margin: 0;
      }
    }
  }
}
.upload {
  width: 100%;
  ::v-deep .el-upload {
    width: 100%;
    .el-upload-dragger {
      width: 100%;
      background: #f3f8ff !important;
    }
  }
}
.el-icon-upload {
  color: #4278c9;
}
.tips {
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.flex-center {
  display: flex;
  //justify-content: center;
  align-items: center;
}
.download-btn {
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0080ff;
  color: #0080ff;
  margin-bottom: 8px;
}
</style>
