<template>
  <div class="container">
    <GeneralTable
      ref="GeneralTable"
      :column="column"
      tableName="jxGeneralManagementList"
      :option="option"
      :dataList="dataList"
      :totalNum="totalNum"
      @search-change="getList"
      @export-data="exportData"
      @import-click="isImport = true"
      @del-change="handleDel"
    >
      <!-- 统计插槽 -->
      <template slot="statistics">
        <!-- <statistics-box
          :statisticsList="statisticsList"
          v-loading="statisticsLoading"
          style="justify-content: space-around"
        /> -->
      </template>
      <template slot="operate-left">
        <!-- <el-button
          type="primary"
          size="mini"
          @click="handleAllocationCustomer()"
          >批量调整手续费</el-button
        > -->
        <!-- <el-button
          type="primary"
          size="mini"
          @click="batchChangeStatus('receive')"
          >批量收款</el-button
        > -->
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-download"
          @click="importCollectionShow = true"
          >导入收款</el-button
        >
      </template>
      <template slot="status" slot-scope="{ item }">
        <el-tag type="success" v-if="item.status">启用</el-tag>
        <el-tag type="danger" v-else>禁用</el-tag>
      </template>
      <template slot="category" slot-scope="{ item }">
        {{
          item.category == 1
            ? "工程机械险"
            : item.category == 2
            ? "车险"
            : "非车险"
        }}
      </template>
      <template slot="officialReceiptsAmount" slot-scope="{ item }">
        <el-button
            type="text" @click="collectionRecordShow = true;currentRow = item;"
            size="small" style="text-decoration: underline;">{{
          item.officialReceiptsAmount || 0
        }}</el-button>
        
      </template>
      
      <template slot="operate" slot-scope="{ item }">
        <div>
          <!-- <el-button
            @click.native.prevent="handleAllocationCustomer(item)"
            type="text"
            size="small"
            >调整手续费</el-button
          > -->
          <el-button
            type="text"
            size="small" :disabled="item.collectionStatus == 2"
            @click="
              collectionShow = true;
              currentRow = item;
            "
            >收款</el-button
          >
          <!-- <el-button
            type="text"
           
            size="small"
            :style="{
              color: item.paymentStatus == 1 ? '#DCDFE6' : '#fca118ff',
            }"
            @click="
              payShow = true;
              currentRow = item;
            "
            :disabled="item.paymentStatus == 1"
            >付款</el-button
          > -->
        </div>
      </template>
      <div
        style="display: flex; align-items: center"
        slot="paymentStatus"
        slot-scope="{ item }"
      >
        <div
          class="status"
          :style="{
            backgroundColor: findDict(item.paymentStatus, 'payStatusDict')
              .color,
          }"
        ></div>
        <span
          :style="{
            color: findDict(item.paymentStatus, 'payStatusDict').color,
          }"
          >{{ findDict(item.paymentStatus, "payStatusDict").dictValue }}</span
        >
      </div>
      <div
        style="display: flex; align-items: center"
        slot="collectionStatus"
        slot-scope="{ item }"
      >
        <div
          class="status"
          :style="{
            backgroundColor: findDict(
              item.collectionStatus,
              'collectStatusDict'
            ).color,
          }"
        ></div>
        <span
          :style="{
            color: findDict(item.collectionStatus, 'collectStatusDict').color,
          }"
          >{{
            findDict(item.collectionStatus, "collectStatusDict").dictValue
          }}</span
        >
      </div>
    </GeneralTable>
    <AddRegulationDialog
      v-model="isAddVisible"
      :editDetail="editData"
      @changeList="changeGetList"
    />
    <CollectionDialog
      :show.sync="collectionShow"
      @submit="handleCollection"
    ></CollectionDialog>
    <PayDialog :show.sync="payShow" @submit="handlePay"></PayDialog>
    <KpiImportDialog
      :show.sync="importPayShow"
      :templateFun="templatePayFun"
      title="导入付款"
      @submit="handleImportPay"
    ></KpiImportDialog>
    <KpiImportDialog
      :show.sync="importCollectionShow"
      :templateFun="templateCollectionFun"
      title="导入收款"
      @submit="handleImportCollection"
    ></KpiImportDialog>
    <KpiImportDetailsDialog
      :show.sync="importDetailsShow" :importObj="kpiImportObj"
      @submit="changeGetList"
    ></KpiImportDetailsDialog>
    <collectionRecordDialog
      :show.sync="collectionRecordShow" :importObj="currentRow"
    ></collectionRecordDialog>
  </div>
</template>
<script>
import { addThousandSeparator } from "@/utils/dealNumber";
import GeneralTable from "@/components/GeneralTable/index.vue";
import StatisticsBox from "@/components/StatisticsBox/index.vue";
import AddRegulationDialog from "@/views/routerPerformanceManagement/components/generalManagementList/components/AddRegulationDialog.vue";
import CollectionDialog from "@/views/routerPerformanceManagement/components/generalManagementList/components/CollectionDialog.vue";
import PayDialog from "@/views/routerPerformanceManagement/components/generalManagementList/components/PayDialog.vue";
import KpiImportDialog from "@/views/routerPerformanceManagement/components/KpiImportDialog.vue";
import KpiImportDetailsDialog from "@/views/routerPerformanceManagement/components/generalManagementList/components/KpiImportDetailsDialog.vue";
import collectionRecordDialog from "@/views/routerPerformanceManagement/components/generalManagementList/components/collectionRecordDialog.vue";
import {
  performanceRemoveByIds,
  dictionaryBatch,
  performanceDownload,
} from "@/api/policy";
import {
  getTotalKpiList,
  getTotalKpiTableHeader,
  getAgentList,
  getSalesmanList,
  getOutTypeList,
  partReceive,
  exportKpiRecord,
  downloadKpiTemplate,
  performanceReceivableList,
  getKpiRuleDetailInfo,
  batchChangePayStatus,
  batchChangeReceiveStatus,
  importKpiPay,
  importCollectionPreview,
  verifyCollectionStatus,
} from "@/api/kpi.js";
import {
  payStatusDict,
  collectStatusDict,
} from "@/views/routerPerformanceManagement/const.js";
import dayjs from "dayjs";
export default {
  name: "ProjectManagementList",
  components: {
    GeneralTable,
    AddRegulationDialog,
    StatisticsBox,
    CollectionDialog,
    PayDialog,
    KpiImportDialog,
    KpiImportDetailsDialog,
    collectionRecordDialog,
  },
  data() {
    return {
      //addThousandSeparator:addThousandSeparator,
      importPayShow: false,
      importCollectionShow: false,
      dataList: [],
      totalNum: 0,
      option: {
        isAdd: false,
        isRefresh: true, // 刷新 @search-change
        isSearch: true,
        isExport: false, // 导入 @import-click
        disFuzzySearch: true,
        pageSize: 10,
        searchList: [
          {
            label: "部门",
            prop: "salesmanDeptName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "保险公司",
            prop: "primaryInsuranceCompanyName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "投保日期",
            prop: "insuranceOfDate",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true,
            prop: ["insuranceStartDate", "insuranceEndDate"],
          },
          {
            label: "险种类型",
            prop: "category",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: [
              { value: "", text: "全部" },
              { value: 1, text: "工程机械" },
              { value: 2, text: "车险" },
              { value: 3, text: "非车险" },
            ],
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "项目",
            prop: "performanceTypeName",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: [],
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "细项",
            prop: "projectName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),performanceType
          },
          {
            label: "业务员",
            prop: "salesmanName",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: [],
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "代理人",
            prop: "agentName",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: [],
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "出单类型",
            prop: "insertType",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: [],
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "收款状态",
            prop: "collectionStatus",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: collectStatusDict,
          },
          {
            label: "支付状态",
            prop: "paymentStatus",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: payStatusDict,
          },
          // {
          //   label: "绩效生成时间",
          //   prop: "createTime",
          //   format: "yyyy.MM.dd",
          //   formType: "daterange",
          //   clearable: true,
          //   prop: ["createStartTime", "createEndTime"],
          // },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        // {
        //   label: "绩效编号",
        //   prop: "serialNo",
        //   isShow: true,
        // },
        {
          label: "保单号",
          prop: "policyNo",
          isShow: true,
        },
        {
          label: "保险公司",
          prop: "primaryInsuranceCompanyName",
          isShow: true,
        },
        {
          label: "分支机构",
          prop: "primaryInsuranceBranchName",
          isShow: true,
        },
        {
          label: "投保日期",
          prop: "insuranceOfDate",
          isShow: true,
        },
        {
          label: "险种类型",
          prop: "category",
          isShow: true,
          isSlot: true,
        },
        {
          label: "项目",
          prop: "performanceTypeName",
          isShow: true,
        },
        {
          label: "细项",
          prop: "projectName",
          isShow: true,
        },
        {
          label: "业务员",
          prop: "salesmanName",
          isShow: true,
        },
        {
          label: "代理人",
          prop: "agentName",
          isShow: true,
        },
        {
          label: "部门",
          prop: "salesmanDeptName",
          isShow: true,
        },
        {
          label: "保费",
          prop: "premium",
          isShow: true,
        },
        {
          label: "绩效类型",
          prop: "insertType",
          isShow: true,
        },
        // //新的字段
        // {
        //   label: "手续费",
        //   prop: "handlingCharge",
        //   isShow: true,
        // },
        // {
        //   label: "保司税率",
        //   prop: "bailiffRate",
        //   isShow: true,
        // },
        // {
        //   label: "我司税率",
        //   prop: "ourCompanyRate",
        //   isShow: true,
        // },
        // {
        //   label: "其他费率",
        //   prop: "otherRate",
        //   isShow: true,
        // },
        // {
        //   label: "税后手续费率",
        //   prop: "afterTaxRate",
        //   isShow: true,
        // },
        // {
        //   label: "税后手续费",
        //   prop: "afterTaxAmount",
        //   isShow: true,
        // },
        // {
        //   label: "业务推荐费率",
        //   prop: "serviceReferralFee",
        //   isShow: true,
        // },
        // {
        //   label: "业务推荐费用",
        //   prop: "serviceReferralAmount",
        //   isShow: true,
        // },
        // {
        //   label: "个人绩效费率",
        //   prop: "commissionFee",
        //   isShow: true,
        // },
        // {
        //   label: "个人绩效金额",
        //   prop: "commissionAmount",
        //   isShow: true,
        // },
        // {
        //   label: "团队长绩效费率",
        //   prop: "teamLeaderFee",
        //   isShow: true,
        // },
        // {
        //   label: "团队长绩效金额",
        //   prop: "teamLeaderAmount",
        //   isShow: true,
        // },
        // {
        //   label: "公估服务费率",
        //   prop: "assessmentServiceFee",
        //   isShow: true,
        // },
        // {
        //   label: "公估服务金额",
        //   prop: "assessmentServiceAmount",
        //   isShow: true,
        // },
        // {
        //   label: "公司保留费率",
        //   prop: "companyReserveFee",
        //   isShow: true,
        // },
        // {
        //   label: "公司保留金额",
        //   prop: "companyReserveAmount",
        //   isShow: true,
        // },
        // {
        //   label: "部门保留率",
        //   prop: "deptReserveFee",
        //   isShow: true,
        // },
        // {
        //   label: "部门保留",
        //   prop: "deptReserveAmount",
        //   isShow: true,
        // },
        {
          label: "实收金额",
          prop: "officialReceiptsAmount",
          isSlot: true,
          isShow: true,
        },
        {
          label: "收款状态",
          prop: "collectionStatus",
          isSlot: true,
          isShow: true,
          width: 100,
        },
        // {
        //   label: "支付状态",
        //   prop: "paymentStatus",
        //   isSlot: true,
        //   isShow: true,
        // },
        //
        // {
        //   label: "绩效生成时间",
        //   prop: "createTime",
        //   isShow: true,
        // },
        {
          label: "更新时间",
          prop: "receivableUpdateTime",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 120,
          fixed: "right",
          isSlot: true,
        },
      ],
      typesOfConstructionMachineryList: [],
      brandInfoPageList: [],
      partBrandList: [],
      deviceTypeList: [],
      isExpansion: false,
      isImport: false,
      isAddVisible: false,
      editData: null,
      oldSearchData: {},
      operateTitle: "添加",
      machineryEquipmentTypeList: [],
      machineryProductTypeList: [],
      ruleForm: {},
      statisticsList: [
        {
          name: "保单总数",
          value: 0,
        },
        {
          name: "保单总保费",
          value: 0,
        },
        {
          name: "税后手续费",
          value: 0,
        },
        {
          name: "公估服务费",
          value: 0,
        },
        {
          name: "公司保留",
          value: 0,
        },
        {
          name: "合计业务推荐费金额",
          value: 0,
        },
      ],
      statisticsLoading: false,
      dictList: {
        payStatusDict: payStatusDict,
        collectStatusDict: collectStatusDict,
      },
      collectionShow: false,
      payShow: false,
      currentRow: null,
      importDetailsShow: false,
      kpiImportObj: {},
      collectionRecordShow: false,
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    init() {
      getOutTypeList().then((res) => {
        this.option.searchList.find((item) => {
          if (item.prop === "insertType") {
            item.selectList = res.data.records.map((code) => {
              return {
                text: code.type,
                value: code.type,
              };
            });
          }
        });
      });
      getAgentList().then((res) => {
        this.option.searchList.find((item) => {
          if (item.prop === "agentName") {
            item.selectList = res.data.records.map((code) => {
              return {
                text: code.userName,
                value: code.userName,
              };
            });
          }
        });
      });
      getSalesmanList().then((res) => {
        this.option.searchList.find((item) => {
          if (item.prop === "salesmanName") {
            item.selectList = res.data.records.map((code) => {
              return {
                text: code.userName,
                value: code.userName,
              };
            });
          }
        });
      });
      dictionaryBatch({ codes: "performanceType" }).then((res) => {
        this.option.searchList.find((item) => {
          if (item.prop === "performanceTypeName") {
            item.selectList = res.data.performanceType.map((code) => {
              return {
                text: code.dictValue,
                value: code.dictValue,
              };
            });
          }
        });
      });
    },
    getList(v, pageNum, pageSize, n, type) {
      if (v.insuranceStartDate) {
        v.insuranceStartDate =
          dayjs(v.insuranceStartDate).format("YYYY-MM-DD") + " 00:00:00";
        v.insuranceEndDate =
          dayjs(v.insuranceEndDate).format("YYYY-MM-DD") + " 23:59:59";
      }
      if (v.createStartTime) {
        v.createStartTime =
          dayjs(v.createStartTime).format("YYYY-MM-DD") + " 00:00:00";
        v.createEndTime =
          dayjs(v.createEndTime).format("YYYY-MM-DD") + " 23:59:59";
      }
      // , deviceType: this.deviceTypeList.toString()
      // , partBrand: this.partBrandList.toString()
      this.oldSearchData = Object.assign(
        { ...this.ruleForm },
        {
          ...v,
          pageIndex: pageNum,
          pageSize: pageSize,
          fuzzyLookupField: v.keyword,
        }
      );
      // performancePage(this.oldSearchData)
      //   .then((res) => {
      //     this.dataList = res.data.records || [];
      //     this.totalNum = res.data.total;
      //   })
      //   .catch((err) => {
      //     this.dataList = [];
      //     this.totalNum = 0;
      //   });
      performanceReceivableList(this.oldSearchData)
        .then((res) => {
          this.dataList = res.data.records || [];
          this.totalNum = res.data.total;
        })
        .catch((err) => {
          this.dataList = [];
          this.totalNum = 0;
        });
      // this.statisticsLoading = true;
      // getTotalKpiTableHeader(this.oldSearchData)
      //   .then((res) => {
      //     this.statisticsLoading = false;
      //     this.statisticsList[0].value = addThousandSeparator(
      //       res.data.policyCount || 0
      //     );
      //     this.statisticsList[1].value = addThousandSeparator(
      //       res.data.policySumPremium || 0
      //     );
      //     this.statisticsList[2].value = addThousandSeparator(
      //       res.data.afterTaxPremium || 0
      //     );
      //     this.statisticsList[3].value = addThousandSeparator(
      //       res.data.assessmentPremium || 0
      //     );
      //     this.statisticsList[4].value = addThousandSeparator(
      //       res.data.companyReservePremium || 0
      //     );
      //     this.statisticsList[5].value = addThousandSeparator(
      //       res.data.referralPremium || 0
      //     );
      //   })
      //   .catch((err) => {
      //     this.statisticsLoading = false;
      //   });
    },
    handleClassification(e, type) {
      // this[type].includes(e) ? this[type].splice(this[type].findIndex ,1) : this[type].push(e);
      this[type].includes(e) ? (this[type] = []) : (this[type] = [e]);
      this.changeGetList();
    },
    changeGetList() {
      this.$refs.GeneralTable.getList();
    },
    goDetail(e) {
      //console.log("详情");
      this.$router.push({
        path: "/routerProjectManagement/projectDetails?id=" + e.id,
      });
    },
    exportData(e) {
      exportKpiRecord({
        ...this.oldSearchData,
      });
    },
    handleDel(e) {
      if (!e || !e.length) {
        this.$message.error("请选择需要删除的员工！");
        return false;
      }
      this.$confirm("删除后该员工当月的绩效会清除，是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = e.map((val) => val.id).toString();
        performanceRemoveByIds(ids).then((res) => {
          this.changeGetList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    editDetail(e) {
      // this.isAddVisible = true;
      // if (e && e.id) {
      //   this.editData = e;
      // }else {
      //   this.editData = null;
      // }
      this.$router.push({
        path: `//routerPerformanceManagement/staffDetails?id=${
          e.id
        }&year=${dayjs(e.yearMonth).format("YYYY")}&month=${dayjs(
          e.yearMonth
        ).format("MM")}`,
      });
    },
    async handleAllocationCustomer(e) {
      // if (!e) {
      //   let customerList = this.$refs.GeneralTable.tableSelectionList;
      //   if (customerList.length) {
      //     this.editData = customerList;
      //     this.isAddVisible = true;
      //   } else {
      //     this.$message.error("请选择绩效");
      //   }
      //   return false;
      // }
      let res = await getKpiRuleDetailInfo({ recordId: e.id });
      this.editData = res.data;
      this.isAddVisible = true;
    },
    batchChangeStatus(type = "") {
      // this.$confirm("是否支付", "支付", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      let list = this.$refs.GeneralTable.tableSelectionList;
      let ids = list.map((val) => val.id);
      if (list.length && ids.length) {
        if (type == "pay") {
          this.$confirm("是否支付", "支付", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            batchChangePayStatus(ids).then(() => {
              this.changeGetList();
              this.$message({
                showClose: true,
                message: "支付成功!",
                type: "success",
              });
            });
          });
        } else if (type == "receive") {
          this.$confirm("是否收款", "收款", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            batchChangeReceiveStatus(ids).then(() => {
              this.changeGetList();
              this.$message({
                showClose: true,
                message: "收款成功!",
                type: "success",
              });
            });
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: "请选择绩效！",
          type: "warning",
        });
      }
    },
    findDict(key, type) {
      let obj =
        this.dictList[type]?.find((item) => {
          return item.dictKey == key;
        }) || {};
      return obj;
    },
    handleCollection(e) {
      verifyCollectionStatus({ ...e, id: this.currentRow.id, receivableId: this.currentRow.performanceReceivableId }).then(() => {
        this.changeGetList();
        this.$message({
          showClose: true,
          message: "收款成功!",
          type: "success",
        });
      });
    },
    handlePay() {
      batchChangePayStatus([this.currentRow.id]).then(() => {
        this.changeGetList();
        this.$message({
          showClose: true,
          message: "支付成功!",
          type: "success",
        });
      });
    },
    templateCollectionFun() {
      downloadKpiTemplate({ paymentFlag: true });
    },
    templatePayFun() {
      downloadKpiTemplate({ paymentFlag: false });
    },
    handleImportCollection(e) {
      let formData = new FormData();
      formData.append("file", e.file);
      importCollectionPreview(formData).then((res) => {
        this.changeGetList();
        this.kpiImportObj = res.data;
        this.importDetailsShow = true;
        // this.$message({
        //   showClose: true,
        //   message: "导入成功!",
        //   type: "success",
        // });
      });
    },
    handleImportPay(e) {
      let formData = new FormData();
      formData.append("file", e.file);
      importKpiPay(formData).then(() => {
        this.changeGetList();
        this.$message({
          showClose: true,
          message: "导入成功!",
          type: "success",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.search-filter {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  .product,
  .brand {
    display: flex;
    .label {
      width: 100px;
      height: 28px;
      line-height: 28px;
      white-space: nowrap;
    }
    .list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .item {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        padding: 0 12px;
        border: 1px solid #4278c9;
        margin: 0 15px 15px 0;
        cursor: pointer;
        border-radius: 4px;
        // &:hover {
        //   color: #4278c9;
        //   background-color: #eff6ff;
        // }
      }
      .check-item {
        color: #ffffff;
        background-color: #4278c9;
        border: 1px solid #4278c9;
      }
    }
  }
  .expansion-box {
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    cursor: pointer;
    font-size: 14px;
    color: #0080ff;
    text-align: center;
  }
}
.enableStatus1 {
}
.enableStatus2 {
}
.slot-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 250px;
    padding: 10px;
    border: 1px solid #dee3e6;
    border-radius: 6px;
    margin: 0 15px 15px 0;
    .img {
      width: 230px;
      height: 230px;
      margin-bottom: 8px;
    }
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .modelNum {
        font-size: 14px;
      }
      .price {
        font-size: 18px;
        color: #fda835;
        font-weight: bold;
      }
    }
    .tags {
      display: flex;
      align-items: center;
      .tag {
        margin-right: 8px;
        padding: 5px 8px;
        background-color: #f2f7fd;
        color: #3470ff;
        border-radius: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 3px;
}
</style>
