<!--
 * @Author: zhuchaowei
 * @Date: 2024-05-24 11:50:44
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-05-29 10:09:44
 * @Description: 
-->
<template>
  <div class="kpi-dr">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      destroy-on-close
      width="970px"
    >
      <div class="xq-box" v-loading="loading">
        <el-table
          :data="collectionList"
          border
          height="480px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="inlayDesc" label="收款方式"> </el-table-column>
          <el-table-column prop="detailAmount" label="收款金额"> </el-table-column>
          <el-table-column prop="detailDate" label="收款时间"> </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
          <el-table-column prop="createTime" label="录入时间"> </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageData.pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <!-- <el-button @click="submitForm" type="primary">完成</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listReceivableDetail } from "@/api/kpi.js";
export default {
  name: "KpiImportDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "收款记录",
    },
    importObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      errTableData: [],
      collectionList: [],
      loading: false,
      pageData: {
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
    };
  },
  watch: {
    show(n) {
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.collectionList = [];
        this.$emit("update:show", false);
      } else {
        this.pageData.pageIndex = 1;
        this.initList();
      }
    },
  },
  methods: {
    async initList() {
      this.loading = true;
      let data = await listReceivableDetail({
        ...this.pageData,
        // id: this.importObj.id,
        receivableId: this.importObj.performanceReceivableId,
      });
      this.loading = false;
      this.collectionList = data.data.records || [];
      this.total = data.data.total;
    },
    clearForm() {},
    handleClose(done) {
      this.clearForm();
      done();
    },
    uploadChange(file, fileList) {
      this.dataForm.file = file.raw;
    },
    async submitForm() {
      this.clearForm();
      this.dialogVisible = false;
    },
    handleSelectionChange(e) {
      this.collectionList = e;
    },
    downloadExport() {
      downloadKpiPreviewExport(this.importObj.failList);
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.pageData.pageIndex = 1;
      this.initList();
    },
    handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      this.initList();
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi-dr {
  ::v-deep .el-dialog {
    border-radius: 8px;
    .el-dialog__header {
      position: relative;
      padding: 0;
      padding-left: 24px;
      height: 40px;
      line-height: 40px;
      // background: #4278C9;
      border-radius: 8px 8px 0px 0px;
      .el-dialog__title {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }
      .el-dialog__headerbtn {
        top: 50%;
        transform: translateY(-50%);
        .el-dialog__close {
          font-weight: bold;
          font-size: 18px;
          color: #333333;
        }
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding: 16px;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
  }
  .xq-box {
    &-success {
      display: flex;
      align-items: center;
      height: 54px;
      font-weight: 400;
      font-size: 14px;
      color: #28b18b;
      margin-bottom: 16px;
      padding-left: 16px;
      background: rgba(40, 177, 139, 0.1);
      i {
        margin-right: 8px;
      }
    }
    &-error {
      display: flex;
      align-items: center;
      height: 54px;
      font-weight: 400;
      font-size: 14px;
      color: #fc3636;
      margin-bottom: 16px;
      padding-left: 16px;
      background: #fcf1f1;
      i {
        margin-right: 8px;
      }
    }
    &-table {
      display: flex;
      align-items: center;
      &-t {
        flex: 1;
        overflow: hidden;
        &-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 50px;
          padding: 0 16px;
          background: #f4f7fa;
          border: 1px solid #dee6ec;
        }
        &-num {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 50px;
          padding: 0 25px;
          font-weight: bold;
          font-size: 16px;
          color: #4278c9;
          border: 1px solid #dee6ec;
        }
      }
      &-img {
        width: 40px;
        height: 84px;
        margin: 0 24px;
      }
      ::v-deep .el-table {
        margin: 0;
      }
    }
  }
}
.upload {
  width: 100%;
  ::v-deep .el-upload {
    width: 100%;
    .el-upload-dragger {
      width: 100%;
      background: #f3f8ff !important;
    }
  }
}
.el-icon-upload {
  color: #4278c9;
}
.tips {
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.flex-center {
  display: flex;
  //justify-content: center;
  align-items: center;
}
.download-btn {
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0080ff;
  color: #0080ff;
  margin-bottom: 8px;
}
</style>
