var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('GeneralTable',{ref:"GeneralTable",attrs:{"column":_vm.column,"tableName":"jxGeneralManagementList","option":_vm.option,"dataList":_vm.dataList,"totalNum":_vm.totalNum},on:{"search-change":_vm.getList,"export-data":_vm.exportData,"import-click":function($event){_vm.isImport = true},"del-change":_vm.handleDel},scopedSlots:_vm._u([{key:"status",fn:function({ item }){return [(item.status)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("启用")]):_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("禁用")])]}},{key:"category",fn:function({ item }){return [_vm._v(" "+_vm._s(item.category == 1 ? "工程机械险" : item.category == 2 ? "车险" : "非车险")+" ")]}},{key:"officialReceiptsAmount",fn:function({ item }){return [_c('el-button',{staticStyle:{"text-decoration":"underline"},attrs:{"type":"text","size":"small"},on:{"click":function($event){_vm.collectionRecordShow = true;_vm.currentRow = item;}}},[_vm._v(_vm._s(item.officialReceiptsAmount || 0))])]}},{key:"operate",fn:function({ item }){return [_c('div',[_c('el-button',{attrs:{"type":"text","size":"small","disabled":item.collectionStatus == 2},on:{"click":function($event){_vm.collectionShow = true;
            _vm.currentRow = item;}}},[_vm._v("收款")])],1)]}},{key:"paymentStatus",fn:function({ item }){return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"status",style:({
          backgroundColor: _vm.findDict(item.paymentStatus, 'payStatusDict')
            .color,
        })}),_c('span',{style:({
          color: _vm.findDict(item.paymentStatus, 'payStatusDict').color,
        })},[_vm._v(_vm._s(_vm.findDict(item.paymentStatus, "payStatusDict").dictValue))])])}},{key:"collectionStatus",fn:function({ item }){return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"status",style:({
          backgroundColor: _vm.findDict(
            item.collectionStatus,
            'collectStatusDict'
          ).color,
        })}),_c('span',{style:({
          color: _vm.findDict(item.collectionStatus, 'collectStatusDict').color,
        })},[_vm._v(_vm._s(_vm.findDict(item.collectionStatus, "collectStatusDict").dictValue))])])}}])},[_c('template',{slot:"statistics"}),_c('template',{slot:"operate-left"},[_c('el-button',{attrs:{"type":"primary","size":"mini","icon":"el-icon-download"},on:{"click":function($event){_vm.importCollectionShow = true}}},[_vm._v("导入收款")])],1)],2),_c('AddRegulationDialog',{attrs:{"editDetail":_vm.editData},on:{"changeList":_vm.changeGetList},model:{value:(_vm.isAddVisible),callback:function ($$v) {_vm.isAddVisible=$$v},expression:"isAddVisible"}}),_c('CollectionDialog',{attrs:{"show":_vm.collectionShow},on:{"update:show":function($event){_vm.collectionShow=$event},"submit":_vm.handleCollection}}),_c('PayDialog',{attrs:{"show":_vm.payShow},on:{"update:show":function($event){_vm.payShow=$event},"submit":_vm.handlePay}}),_c('KpiImportDialog',{attrs:{"show":_vm.importPayShow,"templateFun":_vm.templatePayFun,"title":"导入付款"},on:{"update:show":function($event){_vm.importPayShow=$event},"submit":_vm.handleImportPay}}),_c('KpiImportDialog',{attrs:{"show":_vm.importCollectionShow,"templateFun":_vm.templateCollectionFun,"title":"导入收款"},on:{"update:show":function($event){_vm.importCollectionShow=$event},"submit":_vm.handleImportCollection}}),_c('KpiImportDetailsDialog',{attrs:{"show":_vm.importDetailsShow,"importObj":_vm.kpiImportObj},on:{"update:show":function($event){_vm.importDetailsShow=$event},"submit":_vm.changeGetList}}),_c('collectionRecordDialog',{attrs:{"show":_vm.collectionRecordShow,"importObj":_vm.currentRow},on:{"update:show":function($event){_vm.collectionRecordShow=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }